<template>
    <div class="page">

        <div class="page__header">
            <div class="page__info">
                <div class="page__title">Добавление товара</div>
                <div class="page__desc">Введите данные товара</div>
            </div>
        </div>

        <div class="page__content">
            <form
                    @submit.prevent="create()"
                    class="form-group"
                    method="POST"
                    action=""
                    enctype="multipart/form-data"
                    role="form">

                <label for="title">Название</label>
                <div class="control-group">
                    <input id="title" type="text" name="title" class="form-control" v-model="title" required>
                </div>
                <br>

                <label for="photo">Фото</label><br>
                <div class="control-group">
                    <input ref="photo" id="photo" type="file" name="photo" class="form-control">
                </div>
                <br>

                <label for="category_id">Категория</label>
                <div class="control-group">
                    <select
                            v-model="category_id"
                            id="category_id"
                            name="category_id"
                            class="form-control"
                            required>

                        <template
                                v-for="cc in categoriesTree">

                            <optgroup
                                    v-bind:key="cc.id"
                                    :label="cc.title">

                                <option v-for="c in cc.categories"
                                        v-bind:key="c.id"
                                        :value="c.id"
                                        :selected="category_id === c.id">{{ c.title }}</option>
                            </optgroup>
                        </template>
                    </select>
                </div>
                <br>

                <label for="price">Цена</label>
                <div class="control-group">
                    <input id="price" type="text" class="form-control" v-model="price" placeholder="10.00" required>
                </div>
                <br>

                <label for="weight_value">Вес товара</label>
                <div class="row">
                    <div class="col-sm-4">
                        <div class="control-group">
                            <input id="weight_value" type="text" class="form-control" v-model="weight_value" placeholder="1000" required>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="control-group">
                            <select id="weight_type" class="form-control" v-model="weight_type" required>
                                <option value="GRAM">GRAM</option>
                                <option value="GRAM">ML</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="control-group">
                            <select id="packaging_type" class="form-control" v-model="packaging_type" required>
                                <option value="PIECE">PIECE</option>
                                <option value="WEIGHT">WEIGHT</option>
                            </select>
                        </div>
                    </div>
                </div>
                <br>

                <label for="manufacturer_id">Производитель</label>
                <div class="control-group">
                    <model-select :options="manufacturersOptions"
                                  v-model="manufacturer_id"
                                  id="manufacturer_id"
                                  class="form-control"
                                  required>
                    </model-select>
                </div>
                <br>

                <label for="barcode">Штрихкод</label>
                <div class="control-group">
                    <input id="barcode" type="text" name="barcode" class="form-control" v-model="barcode">
                </div>
                <br>

                <label for="storage_conditions">Условия хранения (℃)</label>
                <div class="control-group">
                    <textarea id="storage_conditions" type="text" name="storage_conditions" class="form-control" placeholder="При температуре от +5℃ до +7℃ градусов" v-model="storage_conditions"></textarea>
                </div>
                <br>

                <label for="ingredients">Состав</label>
                <div class="control-group">
                    <textarea id="ingredients" type="text" name="ingredients" class="form-control" placeholder="100% апельсин" v-model="ingredients"></textarea>
                </div>
                <br>

                <label for="is_hit"><input type="checkbox" id="is_hit" name="is_hit" v-model="is_hit"> Бестселлер</label>
                <br><br>

                <input type="submit" class="btn btn-success btn-large" value="Сохранить">

            </form>
        </div>

    </div>
</template>


<script>
    import { mapActions } from 'vuex'
    import { ModelSelect } from 'vue-search-select'
    import 'vue-search-select/dist/VueSearchSelect.css'

    export default {
        name: 'CatalogProductCreate',
        components: {
            ModelSelect,
        },
        data() {
            return {
                title: null,
                price: null,
                weight_type: null,
                weight_value: null,
                packaging_type: null,
                packaging_value: null,
                category_id: this.$route.params.category_id,
                manufacturer_id: null,
                barcode: null,
                is_hit: false,
                ingredients: null,
                storage_conditions: null
            }
        },
        computed: {
            categoriesTree() {
                return this.$store.state.catalog.categoriesTree
            },
            manufacturers() {
                return this.$store.state.manufacturers.manufacturers != null ? this.$store.state.manufacturers.manufacturers.items : [];
            },
            manufacturersOptions() {
                let options = [];
                for (let i=0; i<this.manufacturers.length; i++) {
                    options.push({
                        value: this.manufacturers[i].id,
                        text: this.manufacturers[i].title,
                    })
                }
                return options;
            }
        },
        methods: {
            ...mapActions([
                'catalogCategoriesTree',
                'catalogProductCreate',
                'catalogManufacturers',
            ]),
            async getProduct() {
                await this.catalogProductGet({
                    id: this.$route.params.id,
                })
            },
            async getCategoriesTree() {
                await this.catalogCategoriesTree()
            },
            async getManufacturers() {
                await this.catalogManufacturers()
            },
            async create() {
                const formData = new FormData();
                formData.append("photo", this.$refs.photo.files[0]);
                formData.append("title", this.title);
                formData.append("price", this.price);
                formData.append("weight_type", this.weight_type);
                formData.append("weight_value", this.weight_value);
                formData.append("packaging_type", this.packaging_type);
                formData.append("packaging_value", this.packaging_type === "PIECE" ? "БР" : "КГ");
                formData.append("category_id", this.category_id);
                formData.append("manufacturer_id", this.manufacturer_id);
                formData.append("barcode", this.barcode);
                formData.append("is_hit", this.is_hit);
                if (this.ingredients != null) {
                    formData.append("ingredients", this.ingredients);
                }
                if (this.storage_conditions != null) {
                    formData.append("storage_conditions", this.storage_conditions);
                }

                await this.catalogProductCreate(formData)
            }
        },
        created() {
            this.getCategoriesTree();
            this.getManufacturers();
        },
    }
</script>